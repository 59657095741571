import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SobreComponent } from './sobre/sobre.component';
import { OrcamentosComponent } from './orcamentos/orcamentos.component';
import { TemplatePadraoComponent } from './template-padrao/template-padrao.component';
import { Page404Component } from './page404/page404.component';


const routes: Routes = [

  {
    path: '',
    component: TemplatePadraoComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
      }, {
        path: 'sobre',
        component: SobreComponent
      }, {
        path: 'orcamento',
        component: OrcamentosComponent
      },{ 
        path: '**', 
        component: Page404Component 
      },
    ]
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
