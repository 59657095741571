import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  resize(n) {
    const dialogRef = this.dialog.open(ModalComponent, {
      panelClass: 'modalClassAdicionar',
      data: n
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

}
