<div class="espacoDestaque">
    <div class="wrap">
        <div class="display">
            <div class="texto">
                <p class="outline">Orçamento</p>
                <p class="normalOutline">Orçamento</p>
            </div>

            <img class="imgHomem" src="../../assets/usando-celular.png" alt="homem no celular">
        </div>
    </div>
</div>

<div class="wrap">
    <div class="card">

        <div class="texto">
            <p>Cadastre-se. Faça orçamentos. Acompanhe pedidos. Experimente um novo jeito de comprar perfis de alumínio no Brasil. Acesse nossa plataforma clicando no botão ao lado.</p>
        </div>

        <div class="botao">
            <a href="https://alumirocket.com.br/login">
                <p>Acessar portal</p>
                <i class="material-icons">arrow_forward</i>
            </a>
        </div>

    </div>
</div>
