<div class="wrap">
    <div class="display">
        <div class="logo">
            <a href="">
                <img src="../../assets/logo_aluminio.svg" alt="Logomarca Aluminio Pantanal">
            </a>
            <p>© {{ano}} Aluminio Pantanal. Todos os direitos reservados.</p>
        </div>

        <div class="endereco">
            <img src="../../assets/icone_endereco.svg" alt="Pin de endereço">
            <a href="https://g.page/aluminiopantanal?share" target="_blank">
                <p>Av. Fernando Corrêa da Costa, 3700- Jardim Shangri-lá, Cuiabá/MT - CEP: 78070-200</p>
            </a>
        </div>
    </div>
</div>