<div class="espacoDestaque">
    <div class="wrap">
        <div class="display">
            <div class="texto">
                <p class="outline">Conheça</p>
                <p class="normalOutline">Sobre nós</p>
            </div>

            <img class="imgPerfil" src="../../assets/perfil_aluminio_sobre.png" alt="perfil de alumínio">
        </div>
    </div>
</div>


<div class="wrap">
    <div class="displaySobre">
        <div class="texto">
            <p>A Alumínio Pantanal começou a escrever sua história no ano de 2010 em Cuiabá, no Estado de Mato Grosso. A companhia começou a crescer com o constante investimento e a alta capacidade técnica de toda sua equipe.</p>
            <p>Atualmente, contamos com os melhores fornecedores de pinturas e perfis de alumínio do país e estamos preparados para atender todas as necessidades do mercado de esquadrias de alumínio de alto padrão, sempre visando proporcionar aos clientes, qualidade, garantia e confiança para o desenvolvimento de novos negócios.</p>
        </div>
        
        <img src="../../assets/icone_aluminio.svg" alt="Ícone da Aluminio Pantanal">
    </div>


    <div class="displayMissao">
        <div class="card">
            <img src="../../assets/missao.svg" alt="missão">
            <h1 class="h1Titulo">Missão</h1>
            <p>Trabalhar com qualidade e garantia para entregar satisfação à nossos clientes.</p>
        </div>

        <div class="card">
            <img src="../../assets/visao.svg" alt="visão">
            <h1 class="h1Titulo">Visão</h1>
            <p>Tornar-se o melhor fornecedor de perfis de alumínio  para a  região Centro-Norte do Brasil, com perfis e pinturas de alto padrão e estoque a pronta entrega quando perguntado em 2025.</p>
        </div>

        <div class="card">
            <img src="../../assets/valores.svg" alt="valores">
            <h1 class="h1Titulo">Valores</h1>
            <ul> 
                <li>Qualidade em primeiro lugar</li> 
                <li>Valorizar quem faz a nossa empresa.</li>
                <li>Crescer e evoluir juntos</li>
            </ul>
        </div>
    </div>
</div>
