import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss']
})
export class RodapeComponent implements OnInit {

  ano: number;

  constructor() {
    var current = new Date()
    this.ano = current.getFullYear();
  }

  ngOnInit(): void {
  }

}
