<div class="wrap">
    <div class="zonaComercial">
        <h1>+100 tipologias de</h1>
        <p>Perfis de Alumínio</p>

        <div class="botao">
            <a href="/orcamento" class="buttonOrcamento2">
                <img src="../../assets/icone_orcamento.svg" alt="ícone de orçamentos">
                Orçamento
            </a>
        </div>

    </div>
</div>

<img class="imgZonaComercial" src="../../assets/perfil_aluminio_zona_comercial.png" alt="perfil de alumínio">

<div class="bgFotos">
    <div class="wrap">

        <div class="textoProduto">
            <p class="outline">Galeria</p>
            <p class="normalOutline">Fotos</p>
            <div class="barraAzul"></div>
        </div>

        <div class="gridProduto">
            <!-- Perfil 1 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(1).jpg" alt="">
                <div id="mouseOver" (click)="resize(1)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Nogueira real</p> -->
                </div>
            </div>

            <!-- Perfil 2 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(2).jpg" alt="">
                <div id="mouseOver" (click)="resize(2)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Nogueira Escura</p> -->
                </div>
            </div>

            <!-- Perfil 3 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(3).jpg" alt="">
                <div id="mouseOver" (click)="resize(3)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Cerejeira Escura Antiga</p> -->
                </div>
            </div>

            <!-- Perfil 4 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(4).jpg" alt="">
                <div id="mouseOver" (click)="resize(4)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Aço Corten Escuro</p> -->
                </div>
            </div>

            <!-- Perfil 5 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(5).jpg" alt="">
                <div id="mouseOver" (click)="resize(5)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Cerejeira Vermelha</p> -->
                </div>
            </div>

            <!-- Perfil 6 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(6).jpg" alt="">
                <div id="mouseOver" (click)="resize(6)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Aço Corten</p> -->
                </div>
            </div>

            <!-- Perfil 7 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(7).jpg" alt="">
                <div id="mouseOver" (click)="resize(7)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Cerejeira Real</p> -->
                </div>
            </div>

            <!-- Perfil 8 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(8).jpg" alt="">
                <div id="mouseOver" (click)="resize(8)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Carvalho Fiammato</p> -->
                </div>
            </div>

            <!-- Perfil 9 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(9).jpg" alt="">
                <div id="mouseOver" (click)="resize(9)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Carvalho Renalit</p> -->
                </div>
            </div>

            <!-- Perfil 10 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(10).jpg" alt="">
                <div id="mouseOver" (click)="resize(10)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Cedro Colonial</p> -->
                </div>
            </div>

            <!-- Perfil 11 -->
            <div class="imgProduto">
                <img id="img" src="../../assets/perfil/perfil-(11).jpg" alt="">
                <div id="mouseOver" (click)="resize(11)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Banco Orco</p> -->
                </div>
            </div>

            <!-- Perfil 12 -->
            <div class="imgProduto">
                <img src="../../assets/perfil/perfil-(12).jpg" alt="">
                <div id="mouseOver" (click)="resize(12)">
                    <img src="../../assets/resize.svg" alt="">
                    <!-- <p>Deep Dark Deco</p> -->
                </div>
            </div>
        </div>
    </div>
</div>