<div class="espacoDestaque">
    <div class="wrap">
        <div class="display">
            <div class="texto">
                <p class="outline">Erro 404</p>
                <p class="normalOutline">Página não encontrada</p>
            </div>
        </div>
    </div>
</div>

