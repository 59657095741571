<div class="menufixo">
    <div class="wrap">
        <div class="display">
            <a href="">
                <img src="../../assets/logo_aluminio.svg" alt="logomarca Aluminio Pantanal">
            </a>

            <nav>
                <a href="/sobre">Sobre nós</a>
                <a href="/orcamento" class="buttonOrcamento">
                    <img src="../../assets/icone_orcamento.svg" alt="ícone de orçamentos">
                    <p>Orçamento</p>
                </a>
            </nav>
        </div>
    </div>
</div>